import {Component} from 'hyperhtml';
import {zt} from '../store/ZoteroState';
import {ux} from '../store/UxState';
import TagFilterItem from './tagFilterItem';
import ItemTypes from './itemTypes';
import ItemTypeFilterItem from './itemTypeFilterItem';
import Search from './search';
import SearchCreator from './searchCreator';
import Reset from './reset';
import Interval from './interval';
import ReviewedFilterItem from './reviewedFilterItem';
import ResearchGroups from './researchGroups';
import Sort from './sort';
import SpecialFilterItem from './specialFilterItem';
import TagFilter from './tagFilter'

class Filter extends Component {
	constructor() {
		super();

		this.tagFilterItem = new TagFilterItem();
		this.tagFilter = new TagFilter();
		this.itemTypeFilterItem = new ItemTypeFilterItem();
		this.search = new Search();
		this.searchCreator = new SearchCreator();
		this.reset = new Reset();
		this.interval = new Interval();
		this.researchGroups = new ResearchGroups();
		this.itemTypes = new ItemTypes();
		this.sort = new Sort();
		this.reviewedFilterItem = new ReviewedFilterItem({label: 'Begutachtete Publikationen'});
		
		zt.subscribe((pr) => {
			this.render();
		});
		ux.subscribe((pr) => {
			this.render();
		});

	}
	
	render() {
		return ux.props.noFilter?
			this.html`<div class="wzb-filteredArticles__filters wzb-filters wzb-filters--publications">

			</div>
			`:
			this.html`
			<div class="m-filteredarticles">
				<div class="container">
					<div class="row">
					${this.search}
					${this.searchCreator}
				    ${this.reset}
					<div class="m-filteredarticles__filters">
						${this.itemTypes}
						${this.interval}
						${this.tagFilter}
					</div>
					<div class="m-filteredarticles__itemCount">${zt.props.total} Publikationen gefunden</div>
					${this.sort}
					</div>
				</div>
			</div>
			`;
	}

}

export default Filter;
